var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:!_vm.insideViewPost ? 'mx-auto px-4' : 'poop elevation-0 px-4',attrs:{"flat":!_vm.insideViewPost,"outlined":!_vm.insideViewPost,"tile":_vm.insideViewPost,"max-width":_vm.insideViewPost ? '100%' : '450'}},[_c('v-card-subtitle',{staticClass:"pt-3 px-0 pb-0 d-flex justify-space-between caption font-weight-medium"},[_c('div',[_c('router-link',{class:_vm.isSameUser
              ? "post-author text-capitalize"
              : "post-author text-capitalize",attrs:{"to":("/u/" + (_vm.post.author))}},[_vm._v(_vm._s(_vm.isSameUser ? 'You' : _vm.post.author)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.rankBadge)+" ")])],1),_vm._v(" in "),_c('router-link',{staticStyle:{"cursor":"pointer","text-decoration":"none"},style:(_vm.circleColor ? 'color: ' + _vm.circleColor : 'color: #795fdc'),attrs:{"to":{
            name: 'View Circle',
            params: { circle_slug: _vm.post.circle_slug }
          },"tag":"a"}},[_vm._v(_vm._s(_vm.post.circle))])],1),_c('span',{staticClass:"caption font-weight-light font-rubik"},[_vm._v(_vm._s(_vm.post.duration)+" ago")])]),_c('v-card-subtitle',{staticClass:"px-0 py-2 subtitle-1 font-weight-bold font-sen"},[_vm._v(" "+_vm._s(_vm.post.caption)+" ")]),_c('keep-alive',[_c(_vm.postType,_vm._b({tag:"component",on:{"view-post":_vm.onViewPost}},'component',_vm.postProps,false))],1),_c('v-card-actions',{staticClass:"px-0 pb-1"},[_c('v-row',{attrs:{"dir":"row","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"dir":"col","cols":"12"}},[(_vm.isSameUser && _vm.insideViewPost)?_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"color":"secondary lighten-1"}},[_vm._v(" "+_vm._s(_vm.mdiDotsVertical)+" ")])],1)]}}],null,false,4084350065)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.deletePost}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"px-0 font-rubik",attrs:{"small":"","color":"secondary","text":""},on:{"click":function($event){!_vm.insideViewPost ? _vm.viewPost(_vm.post.slug, _vm.post.circle_slug) : null}}},[_c('v-icon',{attrs:{"color":"secondary lighten-2"}},[_vm._v(" "+_vm._s(_vm.mdiCommentOutline)+" ")]),_c('span',{staticClass:"font-rubik secondary--text text--lighten-2"},[_vm._v(_vm._s(_vm.post.comments))])],1),(_vm.loggedIn)?_c('v-menu',{staticStyle:{"transform":"translateY(-12px) !important"},attrs:{"top":"","offset-y":"","close-on-click":"","right":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1 px-0 font-rubik",class:_vm.linkCopied ? 'px-2 success lighten-1' : '',attrs:{"small":"","color":"secondary","text":""}},on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.linkCopied),expression:"!linkCopied"}],attrs:{"color":"secondary lighten-2"}},[_vm._v(" "+_vm._s(_vm.mdiShareOutline)+" ")]),_c('span',{staticClass:"font-rubik",class:_vm.linkCopied
                      ? 'white--text'
                      : 'secondary--text text--lighten-2'},[_vm._v(_vm._s(_vm.linkCopied ? 'Copied' : 'Share'))])],1)]}}],null,false,2820691327)},[_c('social-share',{attrs:{"url":("https://link.mybubbl.me/c/" + (_vm.post.circle_slug) + "/p/" + (_vm.post.slug)),"caption":_vm.post.caption,"description":_vm.post.caption,"horizontalIcons":false,"hashtags":"bubbl,cool,fun"},on:{"link-copied":_vm.copiedFeedback}})],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-row"},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":_vm.circleColor || 'primary'},on:{"click":function($event){$event.stopPropagation();_vm.user_upvoted ? _vm.undo('upvote') : _vm.vote('upvote')}}},[_c('v-icon',{attrs:{"color":_vm.user_upvoted
                    ? ("" + (_vm.circleColor || 'primary darken-2'))
                    : 'secondary lighten-2'}},[_vm._v(" "+_vm._s(_vm.mdiThumbUpOutline)+" ")])],1),_c('span',{staticClass:"font-rubik secondary--text text--lighten-1"},[_vm._v(_vm._s(_vm.upvotes - _vm.downvotes))]),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","color":"warning darken-2"},on:{"click":function($event){$event.stopPropagation();_vm.user_downvoted ? _vm.undo('downvote') : _vm.vote('downvote')}}},[_c('v-icon',{attrs:{"color":_vm.user_downvoted ? 'warning darken-2' : 'secondary lighten-2'}},[_vm._v(" "+_vm._s(_vm.mdiThumbDownOutline)+" ")])],1)],1)],1),(_vm.insideViewPost)?_c('v-col',{staticClass:"d-flex px-1 mt-2 mb-2",attrs:{"dir":"col","cols":"12"}},[_vm._t("comment")],2):_vm._e()],1)],1)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }