<template>
  <div>
    <v-img
      :src="
        image ||
          'https://i.ibb.co/vvTrvjL/bubbl-space-background-BLACK-with-OTHERS-02.jpg'
      "
      :height="insideViewPost ? '' : '200px'"
      :contain="false"
      position="center top 70%"
      @click="!insideViewPost ? viewPost() : null"
      v-bind:class="{ 'post-image': !insideViewPost }"
    ></v-img>
    <v-sheet
      width="100%"
      id="longtext"
      :max-height="insideViewPost ? '' : '400px'"
      @click="!insideViewPost ? viewPost() : null"
      color="whitesmoke"
      class="pt-4 px-0"
      v-bind:class="[!insideViewPost ? 'long-text-preview' : 'mt-4']"
      tile
      v-html="
        insideViewPost
          ? parsed_html
          : $options.filters.truncate(parsed_html, 227, '...')
      "
      style="line-height: 1.6;cursor: text;font-size: 17px"
    >
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'LongTextPost',
  props: {
    body: {
      type: String
    },
    caption: {
      type: String
    },
    image: {
      type: String
    },
    parsed_html: {
      type: String
    },
    insideViewPost: {
      type: Boolean
    }
  },
  data: function() {
    return {};
  },
  methods: {
    viewPost() {
      //         v-if="!post.image || post.post_type == 'text'"
      this.$emit('view-post', 'long-text');
    }
  }
};
</script>

<style scoped>
.long-text-preview {
  font-size: 16px !important;
  line-height: 1.3 !important;
}

/* Common Post styles */
a {
  text-decoration: none;
}

.text-16 {
  font-size: 16px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: #000000 !important;
}

.post-image {
  border-radius: 10px;
}

.subtitle-2::first-letter {
  text-transform: capitalize;
}

.v-application .subtitle-2 {
  font-size: 0.95rem !important;
}

.v-card {
  border: none !important;
}

.post-author {
  color: #5f5f5f !important;
}

>>> div#longtext blockquote {
  font-style: normal;
  font-size: 16px !important;
  /* margin-left: 10px; */
  /* font-family: Consolas, "Times New Roman", Verdana; */
  border-left: 4px solid #ccc !important;
  padding-left: 8px !important;
}
/* ----- */
</style>
