<template>
  <v-sheet
    width="100%"
    id="textarea"
    :max-height="insideViewPost ? '' : '400px'"
    @click="!insideViewPost ? viewPost() : null"
    :color="bg_color || 'pink lighten-2'"
    class="white--text px-10 py-5"
    v-bind:class="[!insideViewPost ? 'post-image' : '', alignment]"
    tile
    style="line-height: 28px;flex: 1;font-size: 20px;cursor: text;border: 1px solid white;user-select: none;white-space: pre-line;"
  >
    {{ insideViewPost ? body : $options.filters.truncate(body, 200) }}
  </v-sheet>
</template>

<script>
export default {
  name: 'ShortTextPost',
  props: {
    body: {
      type: String
    },
    alignment: {
      type: String
    },
    bg_color: {
      type: String
    },
    insideViewPost: {
      type: Boolean
    }
  },
  data: function() {
    return {};
  },
  methods: {
    viewPost() {
      //         v-if="!post.image || post.post_type == 'text'"
      this.$emit('view-post', 'short-text');
    }
  }
};
</script>

<style></style>
