<template>
  <v-img
    :src="!imageError ? image : '/img/image-not-found.png'"
    @error="woops"
    @load="loading = false"
    @click="!insideViewPost ? viewPost() : null"
    class="grey lighten-2"
    :aspect-ratio="insideViewPost ? '' : '1'"
    :contain="insideViewPost ? true : false"
    max-width="450"
    max-height="600"
    v-bind:class="{ 'post-image': !insideViewPost }"
  >
    <template v-slot:placeholder>
      <v-sheet style="height: 100%;width: 100%" color="indigo lighten-2">
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-row>
      </v-sheet>
    </template>
  </v-img>
</template>

<script>
export default {
  name: 'ImagePost',
  props: {
    image: {
      type: String
    },
    post_type: {
      type: String
    },
    insideViewPost: {
      type: Boolean
    }
  },
  data: function() {
    return {
      loading: true,
      imageError: false
    };
  },
  methods: {
    woops() {
      //   TODO: Maybe do something with the uri of the image that is not loading,
      // maybe log it somewhere for monitoring purposes?
      this.imageError = true;
    },
    viewPost() {
      this.$emit('view-post', 'image-post');
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.text-16 {
  font-size: 16px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: #000000 !important;
}

.post-image {
  border-radius: 10px;
}

.subtitle-2::first-letter {
  text-transform: capitalize;
}

.v-application .subtitle-2 {
  font-size: 0.95rem !important;
}

.v-card {
  border: none !important;
}

.indigo--text {
  color: #795fdc !important;
}

.post-author {
  color: #5f5f5f !important;
}
</style>
