<template>
  <div>
    <v-card
      :flat="!insideViewPost"
      :outlined="!insideViewPost"
      :tile="insideViewPost"
      :class="!insideViewPost ? 'mx-auto px-4' : 'poop elevation-0 px-4'"
      :max-width="insideViewPost ? '100%' : '450'"
    >
      <v-card-subtitle
        class="pt-3 px-0 pb-0 d-flex justify-space-between caption font-weight-medium"
      >
        <div>
          <router-link
            :class="
              isSameUser
                ? `post-author text-capitalize`
                : `post-author text-capitalize`
            "
            :to="`/u/${post.author}`"
            >{{ isSameUser ? 'You' : post.author }} &nbsp;
            <v-icon x-small>
              {{ rankBadge }}
            </v-icon>
          </router-link>
          in
          <router-link
            style="cursor: pointer;text-decoration: none;"
            v-bind:style="
              circleColor ? 'color: ' + circleColor : 'color: #795fdc'
            "
            :to="{
              name: 'View Circle',
              params: { circle_slug: post.circle_slug }
            }"
            tag="a"
            >{{ post.circle }}</router-link
          >
        </div>

        <span class="caption font-weight-light font-rubik"
          >{{ post.duration }} ago</span
        >
      </v-card-subtitle>
      <v-card-subtitle class="px-0 py-2 subtitle-1 font-weight-bold font-sen">
        {{ post.caption }}
      </v-card-subtitle>

      <!-- IMAGE POST  -->
      <keep-alive>
        <component
          :is="postType"
          v-bind="postProps"
          @view-post="onViewPost"
        ></component>
      </keep-alive>

      <!-- <image :image="post.image" :post_type="post.post_type" :insideViewPost="insideViewPost" @view-post="onViewPost"></image> -->
      <!-- IMAGE POST -->

      <v-card-actions class="px-0 pb-1">
        <v-row dir="row" no-gutters>
          <v-col
            class="d-flex align-items-center justify-content-center"
            dir="col"
            cols="12"
          >
            <v-menu v-if="isSameUser && insideViewPost" top>
              <template v-slot:activator="{ on }">
                <v-btn @click.stop icon v-on="on">
                  <v-icon color="secondary lighten-1">
                    {{ mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="deletePost">
                  <v-list-item-title class="error--text"
                    >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              small
              color="secondary"
              class="px-0 font-rubik"
              @click="
                !insideViewPost ? viewPost(post.slug, post.circle_slug) : null
              "
              text
            >
              <!-- {{ post.comments == 1 ? 'Comment' : 'Comments' }} -->
              <v-icon color="secondary lighten-2">
                {{ mdiCommentOutline }}
              </v-icon>
              <span class="font-rubik secondary--text text--lighten-2">{{
                post.comments
              }}</span>
            </v-btn>
            <v-menu
              v-if="loggedIn"
              top
              offset-y
              close-on-click
              right
              eager
              style="transform: translateY(-12px) !important;"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  small
                  color="secondary"
                  :class="linkCopied ? 'px-2 success lighten-1' : ''"
                  class="ml-1 px-0 font-rubik"
                  text
                >
                  <v-icon v-show="!linkCopied" color="secondary lighten-2">
                    {{ mdiShareOutline }}
                  </v-icon>
                  <span
                    :class="
                      linkCopied
                        ? 'white--text'
                        : 'secondary--text text--lighten-2'
                    "
                    class="font-rubik"
                    >{{ linkCopied ? 'Copied' : 'Share' }}</span
                  >
                </v-btn>
              </template>

              <social-share
                :url="
                  `https://link.mybubbl.me/c/${post.circle_slug}/p/${post.slug}`
                "
                :caption="post.caption"
                :description="post.caption"
                :horizontalIcons="false"
                hashtags="bubbl,cool,fun"
                @link-copied="copiedFeedback"
              ></social-share>
            </v-menu>

            <v-spacer></v-spacer>

            <div
              class="d-flex justify-content-center align-items-center flex-row"
            >
              <v-btn
                icon
                :color="circleColor || 'primary'"
                @click.stop="user_upvoted ? undo('upvote') : vote('upvote')"
                class="mr-1"
              >
                <v-icon
                  :color="
                    user_upvoted
                      ? `${circleColor || 'primary darken-2'}`
                      : 'secondary lighten-2'
                  "
                >
                  {{ mdiThumbUpOutline }}
                </v-icon>
              </v-btn>

              <span class="font-rubik secondary--text text--lighten-1">{{
                upvotes - downvotes
              }}</span>

              <v-btn
                icon
                color="warning darken-2"
                @click.stop="
                  user_downvoted ? undo('downvote') : vote('downvote')
                "
                class="ml-1"
              >
                <v-icon
                  :color="
                    user_downvoted ? 'warning darken-2' : 'secondary lighten-2'
                  "
                >
                  {{ mdiThumbDownOutline }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col
            v-if="insideViewPost"
            class="d-flex px-1 mt-2 mb-2"
            dir="col"
            cols="12"
          >
            <slot name="comment"></slot>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import http from '@/plugins/http';
import SocialShare from '@/components/helpers/social-share.vue';
import { ImagePost, ShortTextPost, LongTextPost } from './types';
// icons
import {
  mdiThumbUpOutline,
  mdiThumbDownOutline,
  mdiShareOutline,
  mdiCommentOutline,
  mdiDotsVertical
} from '@mdi/js';
export default {
  name: 'Post',
  components: { SocialShare, ImagePost, ShortTextPost, LongTextPost },
  props: {
    index: Number,
    post: {
      type: Object,
      required: true
    },
    insideViewPost: {
      type: Boolean,
      default: false
    },
    circleColor: {
      type: String
    }
  },

  data() {
    return {
      loading: true,
      imageError: false,
      user_upvoted: this.post.current_user_has_upvoted || false,
      user_downvoted: this.post.current_user_has_downvoted || false,
      upvotes: this.post.upvotes || 0,
      downvotes: this.post.downvotes || 0,
      linkCopied: false,
      mdiThumbUpOutline,
      mdiThumbDownOutline,
      mdiShareOutline,
      mdiCommentOutline,
      mdiDotsVertical
    };
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
    isSameUser: function() {
      let ans = false;
      if (this.$store.state.user) {
        if (this.post.author && this.$store.state.user.username) {
          ans =
            this.$store.state.user.username.trim().toLowerCase() ==
            this.post.author.trim().toLowerCase();
        }
      }

      return ans;
    },
    rankBadge() {
      let icon = '$baby-face';
      if (this.post.user_rank) {
        switch (this.post.user_rank.trim().toLowerCase()) {
          case 'rookie':
            icon = '$baby-face';
            break;
          case 'regular':
            icon = '$alien';
            break;
          case 'maestro':
            icon = '$smirking-cat';
            break;
          default:
            icon = '$baby-face';
            break;
        }
      }
      return icon;
    },
    postType: function() {
      let postType = 'ImagePost';

      switch (this.post.post_type) {
        case 'image':
          postType = 'ImagePost';
          break;
        case 'text':
          postType = this.post.parsed_html ? 'LongTextPost' : 'ShortTextPost';
          break;
        case 'short-text':
          postType = 'ShortTextPost';
          break;
        case 'long-text':
          postType = 'LongTextPost';
          break;

        default:
          break;
      }

      return postType;
    },
    postProps: function() {
      let postProps = { image: 'yeet.jpg' };

      switch (this.postType) {
        case 'ImagePost':
          postProps = {
            image: this.post.image,
            post_type: this.post.post_type,
            insideViewPost: this.insideViewPost
          };
          break;
        case 'ShortTextPost':
          postProps = {
            body: this.post.body,
            bg_color: this.post.bg_color,
            alignment: this.post.alignment,
            insideViewPost: this.insideViewPost
          };
          break;
        case 'LongTextPost':
          postProps = {
            body: this.post.body,
            caption: this.post.caption,
            image: this.post.image,

            parsed_html: this.post.parsed_html,
            insideViewPost: this.insideViewPost
          };
          break;
        default:
          break;
      }

      return postProps;
    }
  },
  watch: {
    'post.upvotes': function(val) {
      this.upvotes = val;
    },
    'post.downvotes': function(val) {
      this.downvotes = val;
    },
    'post.current_user_has_upvoted': function(val) {
      this.user_upvoted = val;
    },
    'post.current_user_has_downvoted': function(val) {
      this.user_downvoted = val;
    }
    // circleColor: function(val) {
    //   this.mainColor = val;
    // }
  },
  methods: {
    woops() {
      //   TODO: Maybe do something with the uri of the image that is not loading,
      // maybe log it somewhere for monitoring purposes?
      this.imageError = true;
    },
    goTo(circle_slug) {
      this.$router.push(`/c/${circle_slug}`);
    },
    onViewPost() {
      this.$router.push(`/c/${this.post.circle_slug}/p/${this.post.slug}`);
    },
    viewPost(post_slug, circle_slug) {
      this.$router.push(`/c/${circle_slug}/p/${post_slug}`);
    },
    undo(type) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      http
        .delete(`/v1/post/${this.post.slug}/${type}/`, { headers })
        .then(response => {
          console.log(`Success undoing ${type}`, response.data);
          switch (type) {
            case 'upvote':
              this.user_upvoted = false;
              break;
            case 'downvote':
              this.user_downvoted = false;
              break;
            default:
              break;
          }
          this.upvotes = response.data.data.upvotes;
          this.downvotes = response.data.data.downvotes;
        })
        .catch(response => {
          console.log(`Error undoing ${type}`, response.data);
        });
    },
    vote(type) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }
      // Client trick to be faster than the server...
      switch (type) {
        case 'upvote':
          this.upvotes++;
          // remove downvote if user has already downvoted
          this.downvotes += this.user_downvoted ? 1 : 0;
          this.user_upvoted = true;
          this.user_downvoted = false;
          break;
        case 'downvote':
          this.downvotes--;
          // remove upvote if user has already upvoted
          this.upvotes -= this.user_upvoted ? 1 : 0;
          this.user_downvoted = true;
          this.user_upvoted = false;
          break;
        default:
          break;
      }

      http
        .post(
          `/v1/post/${this.post.slug}/${type}/`,
          { sturves: true },
          { headers }
        )
        .then(response => {
          console.log(`Success performing ${type}`, response.data);
          switch (type) {
            case 'upvote':
              this.user_upvoted = true;
              this.user_downvoted = false;
              break;
            case 'downvote':
              this.user_downvoted = true;
              this.user_upvoted = false;
              break;
            default:
              break;
          }
          this.upvotes = response.data.data.upvotes;
          this.downvotes = response.data.data.downvotes;
        })
        .catch(response => {
          console.log(`Error performing ${type}`, response.data);
        });
    },
    deletePost() {
      // todo: change all native alerts to dialogs

      const shouldDelete = window.confirm('yo, wanna delete this post?');

      if (shouldDelete) {
        const headers = {
          Authorization: `token ${this.$store.getters.token}`
        };
        http
          .delete(`/v1/post/${this.post.slug}/`, {
            headers
          })
          .then(response => {
            console.log('Post deleted =>', response.data);
            this.$emit('post-deleted', this.post.slug);
          })
          .catch(response => {
            console.log('res => ', response.data);
          });
      }
    },
    copiedFeedback() {
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 1800);
    }
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.text-16 {
  font-size: 16px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: #000000 !important;
}

.post-image {
  border-radius: 10px;
}

.subtitle-2::first-letter {
  text-transform: capitalize;
}

.v-application .subtitle-2 {
  font-size: 0.95rem !important;
}

.v-card {
  border: none !important;
}

.indigo--text {
  color: #795fdc !important;
}

.post-author {
  color: #5f5f5f !important;
}

/* .ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3e2fa4 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
} */

/** Source of Ripple CSS code: https://codeburst.io/create-a-material-design-ripple-effect-without-js-9d3cbee25b3e */

div#longtext blockquote {
  font-style: normal;
  font-size: 16px !important;
  /* margin-left: 10px; */
  /* font-family: Consolas, "Times New Roman", Verdana; */
  border-left: 4px solid #ccc !important;
  padding-left: 8px !important;
}
</style>
